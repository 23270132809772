import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.section`
  padding: 100px var(--gutter-s) 50px;
  @media (min-width: 768px) {
    padding: 150px var(--gutter-l) 50px;
  }
`

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  color: var(--manatee);
`

const Block = styled.div`
  margin-bottom: 2rem;
  h4,
  p,
  li {
    color: var(--manatee);
    line-height: 150%;
  }
  h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  ol {
    li {
      list-style: upper-alpha;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
`

const Privacy = () => {
  return (
    <Layout metaNavbar={true}>
      <SEO
        title="Privacy"
        keywords={[
          `submitt`,
          `submitt app`,
          `medical app`,
          "medical claims",
          "doctors",
        ]}
      />
      <Container>
        <Heading> Terms </Heading>
        <Block>
          <h4>ACCEPTANCE OF TERMS</h4>
          <p>
            The services that SUBMITT.ME provides to User is subject to the
            following Terms of Use (&#34;TOU&#34;).
          </p>
          <ol>
            <li>
              This Agreement, which incorporates by reference other provisions
              applicable to use of SUBMITT.ME, including, but not limited to,
              supplemental terms and conditions set forth hereof
              (&#34;Supplemental Terms&#34;) governing the use of certain
              specific material contained in SUBMITT.ME, sets forth the terms
              and conditions that apply to use of SUBMITT.ME by User. By using
              SUBMITT.ME (other than to read this Agreement for the first time),
              User agrees to comply with all of the terms and conditions hereof.
              The right to use SUBMITT.ME is personal to User and is not
              transferable to any other person or entity. User is responsible
              for all use of User&#40;s Account (under any screen name or
              password) and for ensuring that all use of User&#40;s Account
              complies fully with the provisions of this Agreement. User shall
              be responsible for protecting the confidentiality of User&#40;s
              password(s), if any.
            </li>
          </ol>
        </Block>

        <Block>
          <h4>DESCRIPTION OF SERVICES</h4>
          <p>
            Through its Web property, SUBMITT.ME provides User with a service to
            relay/access information to a third party nominated by the User
            (such as a Bureau and/or Account Department), for the purposes of
            maintaining an accurate and up to date account of the User’s
            records, accounts, claims and bills (hereafter referred to as “the
            Service/s”).The Service, including any updates, enhancements, new
            features, and/or the addition of any new Web properties, are subject
            to the TOU.
          </p>
        </Block>

        <Block>
          <h4>EQUIPMENT</h4>
          <p>
            User shall be responsible for obtaining and maintaining all
            telephone, computer hardware, software and other equipment needed
            for access to and use of SUBMITT.ME and all charges related thereto.
          </p>
        </Block>

        <Block>
          <h4>USER CONDUCT</h4>
          <ol>
            <li>
              User shall use SUBMITT.ME for lawful purposes only. User shall not
              post or transmit through SUBMITT.ME any material which violates or
              infringes in any way upon the rights of others, which is unlawful,
              threatening, abusive, defamatory, invasive of privacy or publicity
              rights, vulgar, obscene, profane or otherwise objectionable, which
              encourages conduct that would constitute a criminal offense, give
              rise to civil liability or otherwise violate any law. It shall be
              the responsibility of User to obtain any necessary permission from
              their patients/ clients/ customers for any privileged information
              uploaded toSUBMITT.ME.
            </li>
            <li>
              SUBMITT.ME contains copyrighted material, trademarks and other
              proprietary information, including, but not limited to, text,
              software, photos, video, graphics, music and sound, and the entire
              contents of SUBMITT.ME are copyrighted as a collective work under
              the Republic of South Africa’s copyright laws. SUBMITT.ME owns a
              copyright in the selection, coordination, arrangement and
              enhancement of such content, as well as in the content original to
              it. User may not modify, publish, transmit, participate in the
              transfer or sale, create derivative works, or in any way exploit,
              any of the content, in whole or in part. User may download
              copyrighted material for User&#40;s personal use only. Except as
              otherwise expressly permitted under copyright law, no copying,
              redistribution, retransmission, publication or commercial
              exploitation of downloaded material will be permitted without the
              express permission of SUBMITT.ME and the copyright owner. In the
              event of any permitted copying, redistribution or publication of
              copyrighted material, no changes in or deletion of author
              attribution, trademark legend or copyright notice shall be made.
              User acknowledges that it does not acquire any ownership rights by
              downloading copyrighted material.
            </li>
          </ol>
        </Block>

        <Block>
          <h4>USE OF SERVICES</h4>
          <p>User agrees that when using the Services, User will not:</p>
          <ol>
            <li>
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li>
              Publish, post, upload, distribute or disseminate any
              inappropriate, profane, defamatory, obscene, indecent or unlawful
              topic, name, material or information.
            </li>
            <li>
              Upload, or otherwise make available, files that contain images,
              photographs, software or other material protected by intellectual
              property laws, including, by way of example, and not as
              limitation, copyright or trademark laws (or by rights of privacy
              or publicity) unless User own or control the rights thereto or
              have received all necessary consent to do the same.
            </li>
            <li>
              Use any material or information, including images or photographs,
              which are made available through the Services in any manner that
              infringes any copyright, trademark, patent, trade secret, or other
              proprietary right of any party.
            </li>
            <li>
              Upload files that contain viruses, Trojan horses, worms, time
              bombs, cancelbots, corrupted files, or any other similar software
              or programs that may damage the operation of another&#40;s
              computer or property of another.
            </li>
            <li>
              Falsify or delete any copyright management information, such as
              author attributions, legal or other proper notices or proprietary
              designations or labels of the origin or source of software or
              other material contained in a file that is uploaded.
            </li>
            <li>
              Restrict or inhibit any other user from using and enjoying the
              Services.
            </li>
            <li>
              Violate any code of conduct or other guidelines which may be
              applicable for any particular Service.
            </li>
            <li>
              Harvest or otherwise collect information about others, including
              e-mail addresses.
            </li>
            <li>Violate any applicable laws or regulations.</li>
            <li>
              Create a false identity for the purpose of misleading others.
            </li>
            <li>
              Use, download or otherwise copy, or provide (whether or not for a
              fee) to a person or entity any directory of users of the Services
              or other user or usage information or any portion thereof.
              SUBMITT.ME shall not be held accountable for any information
              uploaded to SUBMITT.ME and it shall at all times be the
              responsibility of the User to ensure that any material or
              information uploaded to SUBMITT.ME are correct. It shall be the
              responsibility of the User to keep their own records and files of
              any material or information uploaded to SUBMITT.ME and to check
              the status of any claim submitted.
            </li>
          </ol>
        </Block>

        <Block>
          <h4>MEMBER ACCOUNT, PASSWORD, AND SECURITY</h4>
          <p>
            If any of the Services requires User to open an account, User must
            complete the registration process by providing SUBMITT.ME with
            current, complete and accurate information as prompted by the
            applicable registration form. User also will choose a password and a
            user name. User is entirely responsible for maintaining the
            confidentiality of User’s password and account. Furthermore, User is
            entirely responsible for any and all activities that occur under
            User’s account. User agrees to notify SUBMITT.ME immediately of any
            unauthorized use of User’s account or any other breach of security.
            SUBMITT.ME will not be liable for any loss that User may incur as a
            result of someone else using User’s password or account, either with
            or without User’s knowledge. User may not use anyone else&#40;s
            account at any time, without the permission of the account holder.
          </p>
        </Block>

        <Block>
          <h4>NOTICE SPECIFIC TO INFORMATION AVAILABLE ON THIS WEB SITE</h4>
          <p>
            SUBMITT.ME MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
            INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS
            PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH
            DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED &#34;AS IS&#34; WITHOUT
            WARRANTY OF ANY KIND. SUBMITT.ME HEREBY DISCLAIMS ALL WARRANTIES AND
            CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES
            AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR
            STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT. IN NO EVENT SHALL SUBMITT.ME BE LIABLE FOR ANY
            SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
            RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF
            CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN
            CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM
            THE SERVICES.
          </p>
          <p>
            THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES COULD
            INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
            PERIODICALLY ADDED TO THE INFORMATION HEREIN. SUBMITT.ME MAY MAKE
            IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR THE PROGRAM(S)
            DESCRIBED HEREIN AT ANY TIME.
          </p>
        </Block>

        <Block>
          <h4>
            NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS
            SITE
          </h4>
          <p>
            IN NO EVENT SHALL SUBMITT.ME BE LIABLE FOR ANY SPECIAL, INDIRECT OR
            CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS
            OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT,
            NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION
            WITH THE USE OR PERFORMANCE OF SOFTWARE, DOCUMENTS, PROVISION OF OR
            FAILURE TO PROVIDE SERVICES, OR INFORMATION AVAILABLE FROM THE
            SERVICES.
          </p>
        </Block>

        <Block>
          <h4>
            MATERIALS PROVIDED TO SUBMITT.ME OR POSTED AT ANY OF ITS WEB SITES
          </h4>
          <p>
            SUBMITT.ME does not claim ownership of the materials User provides
            to SUBMITT.ME or post, upload, input or submit to any Services or
            its associated services for review by any third party. However, by
            posting, uploading, inputting, providing or submitting
            (&#34;Posting&#34;) User’s Submission, User is granting SUBMITT.ME
            permission to display and relay User’s Submission in connection with
            the provision of the Service.
          </p>
          <p>
            In addition to the warranty and representation set forth above, by
            Posting a Submission that contain information, images, photographs,
            pictures or that are otherwise graphical in whole or in part
            (&#34;Images&#34;), User warrant and represent that (a) User is the
            copyright owner of such Images, or that the copyright owner of such
            Images has granted User permission to use such Images or any content
            and/or images contained in such Images consistent with the manner
            and purpose of User’s use and as otherwise permitted by these Terms
            of Use and the Services, and (b) that each person depicted in such
            Images, if any, has provided consent to the use of the Images as set
            forth in these Terms of Use.
          </p>
        </Block>

        <Block>
          <h4>DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY</h4>
          <ol>
            <li>
              USER EXPRESSLY AGREES THAT USE OF SUBMITT.ME IS AT USER&#39;S SOLE
              RISK. NEITHER SUBMITT.ME, ITS AFFILIATES NOR ANY OF THEIR
              RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR
              LICENSORS WARRANT THAT SUBMITT.ME WILL BE UNINTERRUPTED OR ERROR
              FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
              OBTAINED FROM USE OF SUBMITT.ME, OR AS TO THE ACCURACY,
              RELIABILITY OR CONTENT OF ANY INFORMATION OR SERVICE PROVIDED
              THROUGH SUBMITT.ME.
            </li>
            <li>
              SUBMITT.ME IS PROVIDED ON AN &#34;AS IS&#34; BASIS WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN
              THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION,
              RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS
              AGREEMENT.
            </li>
            <li>
              THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY
              CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
              INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
              TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR
              DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF
              RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR,
              NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. USER SPECIFICALLY
              ACKNOWLEDGES THAT SUBMITT.ME IS NOT LIABLE FOR THE DEFAMATORY,
              OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD-PARTIES AND
              THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH
              USER.
            </li>
            <li>
              IN NO EVENT WILL SUBMITT.ME, OR ANY PERSON OR ENTITY INVOLVED IN
              CREATING, PRODUCING OR DISTRIBUTING SUBMITT.ME, BE LIABLE FOR ANY
              DAMAGES, INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, AND CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING
              OUT OF THE USE OF OR INABILITY TO USE SUBMITT.ME. USER HEREBY
              ACKNOWLEDGES THAT THE PROVISIONS OF THIS SECTION SHALL APPLY TO
              ALL CONTENT ON THE SITE.
            </li>
            <li>
              IN ADDITION TO THE TERMS SET FORTH ABOVE NEITHER, SUBMITT.ME, NOR
              ITS AFFILIATES, INFORMATION PROVIDERS OR CONTENT PARTNERS SHALL BE
              LIABLE REGARDLESS OF THE CAUSE OR DURATION, FOR ANY ERRORS,
              INACCURACIES, OMISSIONS, OR OTHER DEFECTS IN, OR UNTIMELINESS OR
              UNAUTHENTICITY OF, THE INFORMATION CONTAINED WITHIN SUBMITT.ME, OR
              FOR ANY DELAY OR INTERRUPTION IN THE TRANSMISSION THEREOF TO THE
              USER, OR FOR ANY CLAIMS OR LOSSES ARISING THEREFROM OR OCCASIONED
              THEREBY. NONE OF THE FOREGOING PARTIES SHALL BE LIABLE FOR ANY
              THIRD-PARTY CLAIMS OR LOSSES OF ANY NATURE, INCLUDING, BUT NOT
              LIMITED TO, LOST PROFITS, PUNITIVE OR CONSEQUENTIAL DAMAGES.
            </li>
            <li>
              FORCE MAJEURE – NEITHER PARTY WILL BE RESPONSIBLE FOR ANY FAILURE
              OR DELAY IN PERFORMANCE DUE TO CIRCUMSTANCES BEYOND ITS REASONABLE
              CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF GOD, WAR, RIOT,
              EMBARGOES, ACTS OF CIVIL OR MILITARY AUTHORITIES, FIRE, FLOODS,
              ACCIDENTS, SERVICE OUTAGES RESULTING FROM EQUIPMENT AND/OR
              SOFTWARE FAILURE AND/OR TELECOMMUNICATIONS FAILURES, POWER
              FAILURES, NETWORK FAILURES, FAILURES OF THIRD PARTY SERVICE
              PROVIDERS (INCLUDING PROVIDERS OF INTERNET SERVICES AND
              TELECOMMUNICATIONS). THE PARTY AFFECTED BY ANY SUCH EVENT SHALL
              NOTIFY THE OTHER PARTY WITHIN A MAXIMUM OF FIFTEEN (15) DAYS FROM
              ITS OCCURENCE. THE PERFORMANCE OF THIS AGREEMENT SHALL THEN BE
              SUSPENDED FOR AS LONG AS ANY SUCH EVENT SHALL PREVENT THE AFFECTED
              PARTY FROM PERFORMING ITS OBLIGATIONS UNDER THIS AGREEMENT.
            </li>
          </ol>
        </Block>

        <Block>
          <h4>INDEMNIFICATION</h4>
          <p>
            User agrees to defend, indemnify and hold harmless SUBMITT.ME, its
            affiliates and their respective directors, officers, employees and
            agents from and against all claims and expenses, including
            attorneys&#39; fees, arising out of the use of SUBMITT.ME by User or
            User&#39;s Account.
          </p>
        </Block>

        <Block>
          <h4>PAYMENT OF FEE</h4>
          <p>
            The use of the service is subject to the timeous payment of the
            service fee amounting to R199-00 per month. Billing shall be done by
            way of an online statement rendered to User and User shall be liable
            to pay such service fee on or before the 7th day of each and every
            month. Should the service fee not be paid by the 8th day of the
            month, SUBMITT.ME shall have the right to immediately terminate the
            Service provided to User and terminate User’s access to SUBMITT.ME.
          </p>
        </Block>

        <Block>
          <h4>TERMINATION</h4>
          <p>
            Either SUBMITT.ME or User may terminate this Agreement by giving 30
            calendar days’ written notice of such termination. Without limiting
            the foregoing, SUBMITT.ME shall have the right to immediately
            terminate User&#39;s Account in the event of any conduct by User
            which SUBMITT.ME, in its sole discretion, considers to be
            unacceptable, or in the event of any breach by User of this
            Agreement.
          </p>
        </Block>

        <Block>
          <h4>MISCELLANEOUS</h4>
          <p>
            This Agreement and any operating rules established by SUBMITT.ME,
            constitute the entire agreement of the parties with respect to the
            subject matter hereof, and supersede all previous written or oral
            agreements between the parties with respect to such subject matter.
            This Agreement shall be construed in accordance with the laws of the
            Republic of South Africa. No waiver by either party of any breach or
            default hereunder shall be deemed to be a waiver of any preceding or
            subsequent breach or default. The section headings used herein are
            for convenience only and shall not be given any legal import.
          </p>
        </Block>

        <Block>
          <h4>HAT DO WE DO WITH YOUR INFORMATION?</h4>
          <p>
            When you use our app, we save the personal information you provide
            us with such as your first name, last name, email address and phone
            number. When you visit our website, we also automatically receive
            your computer’s IP address that helps us learn about your browser
            and operating system.
          </p>
        </Block>

        <Block>
          <h4>CONSENT</h4>
          <p>
            When you provide us with personal information to start your
            subscription or verify your credit card, we imply that you consent
            to our collecting it and using it for that specific reason only.
          </p>
          <p>
            If we ask for your information for marketing, we will either ask you
            directly for your consent, and provide you with the option to say
            no.
          </p>
          <p>
            If after you opt-in to marketing and you change your mind, you may
            withdraw your consent by emailing us at info@submitt.appDISCLOSUREWe
            may disclose your personal information if required to do so by
            law.PAYMENTWe use Stripe to process payments. They are our
            PCI-compliant payments partner and trusted worldwide. PCI-DSS
            requirements help ensure the secure handling of credit card
            information by our app and its service providers.
          </p>
        </Block>

        <Block>
          <h4>THIRD PARTY SERVICES</h4>
          <p>
            Our third-party partners used will only collect, use and disclose
            your information necessary to allow them to perform the services
            required by Submitt.Certain third-party service providers have their
            own privacy policies in respect to the information required for
            purchase-related transactions. We recommend that you read these
            privacy policies so you can understand the manner in which your
            personal information will be handled by these providers.
          </p>
          <p>
            <strong>Links: </strong> When you click on links on our website,
            they may direct you away from our site. We are not responsible for
            their privacy practices.
          </p>
        </Block>

        <Block>
          <h4>SECURITY</h4>
          <p>
            To protect your personal information, we take reasonable precautions
            and follow industry best practices to make sure it is not
            inappropriately lost, misused, accessed, disclosed, altered or
            destroyed. We do not store any credit card details, these are
            securely stored by our third-party partner Stripe.
          </p>
        </Block>

        <Block>
          <h4>AGE OF CONSENT</h4>
          <p>
            By using this site, you represent that you are at least the age of
            majority in your state or province of residence and you have given
            us your consent to allow any of your minor dependents to use this
            site.
          </p>
        </Block>

        <Block>
          <h4>UPDATES</h4>
          <p>
            We reserve the right to modify this privacy policy at any time, so
            please review it frequently. 
          </p>
          <p>
            If our app is acquired or merged with another company, your
            information may be transferred to the new owners so that we may
            continue to sell products to you.
          </p>
        </Block>
      </Container>
    </Layout>
  )
}

export default Privacy
